import {
  CommandBar,
  IButtonStyles,
  ICommandBarItemProps,
  ICommandBarStyles,
  IIconStyles,
  mergeStyles, NeutralColors,
} from '@fluentui/react';
import { downloadAttachment } from 'modules/fileAttachment/fileAttachment';
import { openLinkInNewTab } from 'modules/links/links';
import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { RedirectButton } from 'components/button/redirectButton';
import { pricingCalculatorRouteWithSubpath } from 'modules/routes/routes';
import { ConfigContext } from '../../components/configProvider/configContext';
import { getSectionName } from './pageNavigation.functions';

const navBarInnerStyles = mergeStyles({
  width: '1375px',
  height: '60px',
  boxShadow: '0 4px 4px rgba(0, 0, 0, 25%)',
  margin: '0 auto',
  left: 0,
  right: 0,
  marginTop: '-30px',
  position: 'sticky',
  top: 0,
  backgroundColor: NeutralColors.white,
  zIndex: 10,
  display: 'flex',
  fontSize: '14px',
});

const commandBarClassStyles = mergeStyles({
  marginTop: 'auto',
  marginBottom: '3px',
});

const iconStyles: IIconStyles = {
  root: {
    color: '#424242',
  },
};

const contactUsStyle = mergeStyles({
  height: '32px !important',
  width: '99px !important',
  margin: 'auto 20px',
  backgroundColor: '#0078D4',
  borderRadius: '4px',
  fontWeight: 600,
  whiteSpace: 'nowrap',
});

const selectedStyles: IButtonStyles = {
  label: {
    fontWeight: 600,
  },
  flexContainer: {
    borderBottom: '3px solid #0F6CBD',
    selectors: {
      ':hover': {
        backgroundColor: '#eaebec',
      },
    },
  },
};

const emptyCommandBarStyles: Partial<ICommandBarStyles> = {};

const commandBarStyles: Partial<ICommandBarStyles> = {
  root: {
    width: '100%',
    border: 'none',
    selectors: {
      button: {
        padding: '0 8px',
        selectors: {
          ':hover': {
            backgroundColor: '#eaebec',
            textDecoration: 'underline',
          },
          ':focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};

const contextualMenuItemStyles = mergeStyles({
  '.ms-ContextualMenu-link': {
    '.ms-ContextualMenu-icon': {
      color: '#424242',
    },
    selectors: {
      ':hover': {
        backgroundColor: '#eaebec',
        textDecoration: 'underline',
        '.ms-ContextualMenu-icon': {
          color: '#0078d4',
        },
      },
      ':focus': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export enum PageNavigationItemNames {
  Overview = 'Overview',
  WhyJWCC = 'Why JWCC?',
  GettingStarted = 'Getting started',
  PricingCalculator = 'Pricing calculator',
  Documentation = 'Documentation',
  CloudSupportServices = 'Cloud support services',
  TrainingCertification = 'Training & certification',
  FAQs = 'FAQs',
  OtherResources = 'Help and other resources',
  UserGuide = 'User guide',
  Catalog = 'Catalog',
  ProductTerms = 'Product terms',
  JwccSupportPage = 'JWCC support page',
}

export enum PageNavigationAnchorNames {
  Overview = 'overview-anchor',
  WhyJWCC = 'why-jwcc-anchor',
  GettingStarted = 'getting-started-anchor',
  CloudSupportServices = 'cloud-support-services-anchor',
  FAQs = 'faqs-anchor',
}

export const PageNavBar: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const [selectedItem, setSelectedItem] = useState<string>(PageNavigationItemNames.Overview);
  const scrollableElement = document.getElementById('scrollable');
  const contactUsUrl = configContext.serverConfig?.contactUsEndpoint;
  const contactUsEndpoint = `/${contactUsUrl?.split('/').pop()}`;

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    if (!scrollableElement || !sections) {
      return;
    }

    scrollableElement.onscroll = () => {
      sections.forEach((section: HTMLElement) => {
        const top = scrollableElement.scrollTop;
        const offset = section.offsetTop;
        const height = section.offsetHeight;

        // This logic - at what point do we want to change the selected section? When it is at the top of the screen?
        if (top >= offset && top < offset + height) {
          setSelectedItem(getSectionName(section.id as PageNavigationAnchorNames));
        }
      });
    };
  }, [scrollableElement]);

  const onItemClick = (buttonKey: string, anchor: string) => {
    setSelectedItem(buttonKey);

    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const items: ICommandBarItemProps[] = [
    {
      name: PageNavigationItemNames.Overview,
      key: PageNavigationItemNames.Overview,
      buttonStyles: selectedItem === PageNavigationItemNames.Overview ? selectedStyles : emptyCommandBarStyles,
      onClick: () => onItemClick(PageNavigationItemNames.Overview, PageNavigationAnchorNames.Overview),
    },
    {
      name: PageNavigationItemNames.WhyJWCC,
      key: PageNavigationItemNames.WhyJWCC,
      buttonStyles: selectedItem === PageNavigationItemNames.WhyJWCC ? selectedStyles : emptyCommandBarStyles,
      onClick: () => onItemClick(PageNavigationItemNames.WhyJWCC, PageNavigationAnchorNames.WhyJWCC),
    },
    {
      name: PageNavigationItemNames.GettingStarted,
      key: PageNavigationItemNames.GettingStarted,
      buttonStyles: selectedItem === PageNavigationItemNames.GettingStarted ? selectedStyles : emptyCommandBarStyles,
      onClick: () => onItemClick(PageNavigationItemNames.GettingStarted, PageNavigationAnchorNames.GettingStarted),
    },
    {
      name: PageNavigationItemNames.PricingCalculator,
      key: PageNavigationItemNames.PricingCalculator,
      buttonStyles: selectedItem === PageNavigationItemNames.PricingCalculator ? selectedStyles : emptyCommandBarStyles,
      onClick: () => openLinkInNewTab(configContext.serverConfig?.internalPricingCalculatorEndpoint || ''),
      iconProps: { iconName: 'OpenInNewTab', styles: iconStyles },
    },
    {
      name: PageNavigationItemNames.Documentation,
      key: PageNavigationItemNames.Documentation,
      buttonStyles: selectedItem === PageNavigationItemNames.Documentation ? selectedStyles : emptyCommandBarStyles,
      onClick: () => openLinkInNewTab(configContext.serverConfig?.documentationEndpoint || ''),
      iconProps: { iconName: 'OpenInNewTab', styles: iconStyles },
    },
    {
      name: PageNavigationItemNames.CloudSupportServices,
      key: PageNavigationItemNames.CloudSupportServices,
      buttonStyles: selectedItem === PageNavigationItemNames.CloudSupportServices ? selectedStyles : emptyCommandBarStyles,
      onClick: () => onItemClick(PageNavigationItemNames.CloudSupportServices, PageNavigationAnchorNames.CloudSupportServices),
    },
    {
      name: PageNavigationItemNames.TrainingCertification,
      key: PageNavigationItemNames.TrainingCertification,
      buttonStyles: selectedItem === PageNavigationItemNames.TrainingCertification ? selectedStyles : emptyCommandBarStyles,
      onClick: () => openLinkInNewTab(configContext.serverConfig?.trainingCertificationEndpoint || ''),
      iconProps: { iconName: 'OpenInNewTab', styles: iconStyles },
    },
    {
      name: PageNavigationItemNames.OtherResources,
      key: PageNavigationItemNames.OtherResources,
      subMenuProps: {
        items: [
          {
            name: PageNavigationItemNames.UserGuide,
            key: PageNavigationItemNames.UserGuide,
            className: contextualMenuItemStyles,
            onClick: () => { downloadAttachment(configContext.serverConfig?.userGuideDownloadName || ''); },
            iconProps: { iconName: 'Download', styles: iconStyles },
          },
          {
            name: PageNavigationItemNames.Catalog,
            key: PageNavigationItemNames.Catalog,
            className: contextualMenuItemStyles,
            onClick: () => { downloadAttachment(configContext.serverConfig?.catalogDownloadName || ''); },
            iconProps: { iconName: 'Download', styles: iconStyles },
          },
          {
            name: PageNavigationItemNames.ProductTerms,
            key: PageNavigationItemNames.ProductTerms,
            className: contextualMenuItemStyles,
            onClick: () => { downloadAttachment(configContext.serverConfig?.productTermsDownloadName || ''); },
            iconProps: { iconName: 'Download', styles: iconStyles },
          },
          {
            name: PageNavigationItemNames.JwccSupportPage,
            key: PageNavigationItemNames.JwccSupportPage,
            buttonStyles: selectedItem === PageNavigationItemNames.JwccSupportPage ? selectedStyles : emptyCommandBarStyles,
            onClick: () => openLinkInNewTab(pricingCalculatorRouteWithSubpath(configContext.serverConfig?.jwccHelpAndSupportStartGuideName || '')),
          },
          {
            name: PageNavigationItemNames.FAQs,
            key: PageNavigationItemNames.FAQs,
            className: contextualMenuItemStyles,
            onClick: () => { onItemClick(PageNavigationItemNames.FAQs, PageNavigationAnchorNames.FAQs); },
          },
        ],
      },
    },
  ];

  return (
    <div className={navBarInnerStyles}>
      <CommandBar className={commandBarClassStyles} styles={commandBarStyles} items={items} />
      <RedirectButton
        text="Contact Us"
        endpoint={contactUsEndpoint || ''}
        styles={contactUsStyle}
      />
    </div>
  );
};
